import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    setTimeout(()=>{
      window.scrollTo(0, 0)
    },20) 
    setTimeout(()=>{
      document.querySelector('html').style.scrollBehavior = 'smooth'
    },500)    
  }, [pathname]);

  return null;
}

export {ScrollToTop};