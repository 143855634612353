import Footer from './components/footer'
import Header from './components/header'
import Main from './pages/Main'
import Case from './pages/Case'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {ScrollToTop} from './components/scrollToTop.js'
import { useState, useEffect } from 'react'

function App() {
  const [data,setData] = useState({
    mainLabel: '',
    mainH1: '',
    mainBanner: {},
    mainVideo: '',
    mainSource: '',
    workLabel: '',
    workH: '',
    workTags: '',
    aboutLabel: '',
    aboutText: '',
    contactsLabel: '',
    contactsText: ''
  })
  useEffect(() => {
    const fetchUrl = window.location.protocol + '//annation.eu/cp/'
    fetch(fetchUrl, {method: 'POST'})
    .then(response => response.json())
    .then(result => setData(result))
  },[])
  return (
    <Router>
    <ScrollToTop />
    <div className="App">
      <Header /> 
      <Routes>
        <Route exact path="/" element={<Main data={data} />} />
        <Route path="/:caseUrl" element={<Case />} />
      </Routes>
      <Footer data={data} />
    </div>
    </Router>
  );
}

export default App;
