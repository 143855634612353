const Input = ({type = 'text', name, text}) => {
    return(
        <div className="input-wrapper">
            <input type={type} name={name} placeholder={text} required />
            <span>{text}</span>
        </div>
    )
}

export default function Footer({data}) {
    const changeInput = (e) => {
        let elem = e.currentTarget
        let h = elem.value.split('\n').length * 25 + 40
        elem.style.height = (h < 90 ? 90 : h) + 'px'
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let form = e.currentTarget
        if (form.checkValidity()) {
            let formData = new FormData()
            let url = 'https://annation.eu/cp/orders2/'
            formData.append('name', form.querySelector('[name="name"]').value)
            formData.append('phone', form.querySelector('[name="phone"]').value)
            formData.append('email', form.querySelector('[name="email"]').value)
            formData.append('message', form.querySelector('[name="message"]').value)
            fetch(url, {method: 'POST', body: formData})
            .then(
                [...form.querySelectorAll('input'),form.querySelector('[name="message"]')].forEach(elem => elem.value = '')
            )
        }
    }
    return(
    <>
      <section id="contacts">
          <div className='container'>
              <div className="container-small">
                  <div className="label">{data.contactsLabel}</div>
                  <div className="h2">{data.contactsText}</div>
                  <form onSubmit={(e)=>{handleSubmit(e)}}>
                    <div className="input-row">
                        <Input type="text" name="name" text="Name" />
                        <Input type="text" name="phone" text="Phone" />
                        <Input type="email" name="email" text="Email" />
                    </div>
                    <div className="input-wrapper">
                        <textarea name="message" id="Message" cols="30" rows="2" placeholder="Message"
                            onInput={(e)=>{changeInput(e)}}
                        ></textarea>
                        <span>Message</span>
                    </div>
                    <div className="relative">
                        <div className="agreement">By clicking ‘Send’ you give consent to have your personal data processed</div>
                        <button type="submit">Send</button>
                    </div>
                  </form>
                  <div className="h4">or send me an email <a href="mailto:annationfilming@gmail.com">annationfilming@gmail.com</a></div>
              </div>
          </div>
      </section>
      <footer>
          <div className="container">
            <div className="copyright">© 2022, Landenok</div>
          </div>
      </footer>
    </>
    )
}