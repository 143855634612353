import { useEffect, useRef, useState } from 'react'
import { Link, useParams, useLocation } from "react-router-dom"
import { gsap } from "gsap"
import '../fancybox.min.css'
import { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

function MediaViewer({ link, poster }) {
    const [fileType, setFileType] = useState(null);

    useEffect(() => {
        async function fetchFileType() {
            let response = await fetch(link);
            setFileType(response.headers.get('content-type'));
        }
        fetchFileType();
    }, [link]);

    if (fileType) {
        if (fileType.includes("image")) {
            return <img data-fancybox="projects" href={link} src={link} alt="alt" />
        }
        if (fileType.includes("video")) {
            return <video src={link} preload='metadata' poster={poster} controls={true} controlsList='nodownload noplaybackrate' />
        }
    }
}

export default function Case() {
    const url = window.location.protocol + '//annation.eu/cp/'
    const ref = useRef()
    const { caseUrl } = useParams()
    const { pathname } = useLocation()
    const [projects,setProjects] = useState([])
    let s = window.innerWidth < 1024 ? (window.innerWidth < 768 ? 10 : 30) : 50
    const [spaceBetween, setSpaceBetween] = useState(s)
    const [data,setData] = useState({
        bg: '',
        title: '',
        task: '',
        year: '',
        video: '',
        poster: '',
        content: '',
        wide: false,
        bsTitle: '',
        bsGallery: {}
    })
    const Project = ({props: {link,title,src,poster,tags = '',wide = false}}) => {
        return (
            <Link to={'/' + link} data-tags={tags} className={"gallery-item" + (wide ? ' gallery-item_wide' : '')}>
                <video src={url + src} poster={url + poster} loop={true}></video>
                <div className="gallery-title">{title}</div>
            </Link>
        )
    }
    useEffect(()=>{
        window.addEventListener('resize',()=>{
            let s = window.innerWidth < 1024 ? (window.innerWidth < 768 ? 10 : 30) : 50
            setSpaceBetween(s)
        })
    },[])
    useEffect(() => {
        gsap.to(".bg-slider", {opacity: 0, duration: 0})
        gsap.to(".main-text > *", {opacity: 0, duration: 0})
        gsap.fromTo(".header", {opacity: 0}, {opacity: 1, duration: 0.5}, '+=0.5')
        fetch(url + caseUrl)
        .then(response => response.json())
        .then(result => {
            setData(result)
            ref.current.innerHTML = result.content
        })
        fetch(url + 'projects/?limit=5&current=' + pathname.slice(1))
        .then(response => response.json())
        .then(result => setProjects(result))
    },[pathname])
    useEffect(() => {
        let tl = gsap.timeline()
        tl.fromTo(".main-text > *", {opacity: 0, y: 50}, {opacity: 1, y: 0, stagger: 0.1})
        .to(".bg-slider", {opacity: 0.2, duration: 0})
    },[data])
    useEffect(() => {
        let videos = document.querySelectorAll('.gallery-item video')
        const vplay = (v)=>{v.play()}
        const vpause = (v)=>{v.pause()}
        videos.forEach(v => {
            v.addEventListener('mouseenter',()=>{vplay(v)})
            v.addEventListener('mouseleave',()=>{vpause(v)})
        });
        return(()=>{
            videos.forEach(v => {
                v.removeEventListener('mouseenter',()=>{vplay(v)})
                v.removeEventListener('mouseleave',()=>{vpause(v)})
            });
        })
    },[projects])
    return(
        <div className="page case">
            <section id="main">
                <div className="bg-slider">
                    <div className="bg-slider-row">
                        <img src={url + data.bg} alt="alt" />
                    </div>
                </div>
                <div className="main-text">
                    {data.title === '' ? '' : <>
                        <div style={{opacity: 0, y: 50}} className="label">name project</div>
                        <h1 style={{opacity: 0, y: 50}}>{data.title}</h1>
                    </>}
                    {data.task === '' ? '' : 
                        <div style={{opacity: 0, y: 50}} className="task">
                            <div className="label">A task</div>
                            <h4>{data.task}</h4>
                        </div>
                    }
                    {data.year === '' ? '' : 
                        <div style={{opacity: 0, y: 50}} className="task">
                            <div className="label">year</div>
                            <h4>{data.year}</h4>
                        </div>
                    }
                </div>
            </section>
            <div className="container">
                <div className="case-content">
                    <div className="container-small">
                        <video 
                            onCanPlay={(e)=>{ref.current.style.maxWidth = e.target.clientWidth + 'px'}}
                            src={url + data.video}
                            poster={url + data.poster}
                            controls={true}
                            controlsList='nodownload noplaybackrate'
                            preload='metadata'
                        >
                        </video>
                        <div ref={ref} className={"case-content__text" + (data.wide ? ' wide' : '')}></div>
                    </div>
                </div>
                {data.bsGallery && data.bsGallery.length > 0 && <div className="case-gallery">
                    <div className="container-small">
                        <h3>{data.bsTitle}</h3>
                        <Swiper
                            modules={[Mousewheel]}
                            spaceBetween={spaceBetween}
                            slidesPerView='auto'
                            mousewheel={true}
                            centerInsufficientSlides={true}
                        >
                            {data.bsGallery.map(bs => {
                                return  <SwiperSlide>
                                            <MediaViewer key={bs.MIGX_id} link={url + bs.img} poster={url + bs.poster} />
                                        </SwiperSlide>
                            })}
                        </Swiper>
                    </div>
                </div>}
                <div className="case-other">
                    <h3>Other projects</h3>
                    <div className="case-other-wrapper">
                        {projects.map((p,idx) => {return(
                            <Project key={idx} props={p} />
                        )})}
                    </div>
                </div>
            </div>
        </div>
    )
}