import { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom"
import { gsap } from "gsap"

export default function Main({data}) {
    const url = window.location.protocol + '//annation.eu/cp/'
    const autoPlay = true
    const ref = useRef()
    const [galleryWidth,setGalleryWidth] = useState(5)
    const [projects,setProjects] = useState([])
    const checkGallerySize = () => {
        if (window.innerWidth >= 1600) {setGalleryWidth(5)} 
        else if (window.innerWidth >= 1024) {setGalleryWidth(4)}
        else if (window.innerWidth >= 768) {setGalleryWidth(3)}
        else {setGalleryWidth(2)}
    }
    const Project = ({props: {link,title,src,poster,tags = '',wide = false}}) => {
        return (
            <Link to={link} data-tags={tags} className={"gallery-item" + (wide ? ' gallery-item_wide' : '')}>
                <video src={url + src} poster={url + poster} loop={true}></video>
                {/* <div className="gallery-title">{title}</div> */}
            </Link>
        )
    }
    useEffect(() => {
        if (data.mainH1 !== '') {
            gsap.fromTo('.main-text > *',{opacity: 0, y: 50}, {opacity: 1, y: 0, stagger: 0.2})
        }
    },[data])
    useEffect(() => {
        checkGallerySize()
        fetch(url + 'projects/')
        .then(response => response.json())
        .then(result => setProjects(result))
        window.addEventListener('resize',checkGallerySize)
        window.addEventListener('keypress',(e)=>{
            if (e.key == 'f') {setGalleryWidth(4)}
        })
        return(()=>{
            window.removeEventListener('resize',checkGallerySize)
        })
    },[])
    const renderGallery = () => {
        let row =  0, counter = 0
        let items = ref.current.querySelectorAll('.gallery-item')
        items.forEach(v => {
            v.style.order = 'unset'
            v.classList.remove('rendered')
        })
        let itemWideWeight = window.innerWidth >= 768 ? 3 : 2
        items.forEach((v,index) => {
            if (!v.classList.contains('rendered') && !v.classList.contains('hidden')) {
                let weight = v.classList.contains('gallery-item_wide') ? itemWideWeight : 1
                counter += weight
                if (counter <= galleryWidth) {
                    v.style.order = row
                }
                if (counter == galleryWidth) {
                    counter = 0
                    row++
                }
                if (counter == galleryWidth + 1) {
                    let find = false
                    counter = 1
                    v.style.order = row
                    let k = index - 1
                    while (!find && items[k]) {
                        if (!items[k].classList.contains('gallery-item_wide') && !items[k].classList.contains('rendered')
                        && !items[k].classList.contains('hidden')) {
                            find = true
                            items[k].style.order = row + 1
                            items[k].classList.add('rendered')
                        } else {k--;}
                    }
                    if (!find) {
                        counter = itemWideWeight
                        v.style.order = row + 1
                        k = index + 1
                        let find1 = false, find2 = false
                        while ((!find1 || !find2) && items[k]) {
                            if (!items[k].classList.contains('gallery-item_wide') && !items[k].classList.contains('rendered')
                            && !items[k].classList.contains('hidden')) {
                                if (!find1) {find1 = true} else {find2 = true}
                                items[k].style.order = row
                                items[k].classList.add('rendered')
                            } else {k++;}
                        }
                    }
                    row++
                }
                if (counter == galleryWidth + 2) {
                    let find = false
                    counter = itemWideWeight
                    v.style.order = row + 1
                    let k = index + 1
                    while (!find && items[k]) {
                        if (!items[k].classList.contains('gallery-item_wide') && !items[k].classList.contains('rendered')
                        && !items[k].classList.contains('hidden')) {
                            find = true
                            items[k].style.order = row
                            items[k].classList.add('rendered')
                        } else {k++;}
                    }
                    row++
                }
                if (counter == galleryWidth) {
                    counter = 0
                    row++
                }
            }
        });
    }
    const filterGallery = (e,tag) => {
        e.preventDefault()
        e.stopPropagation()
        document.querySelectorAll('.categories a').forEach(elem => {
            elem == e.target ? elem.classList.add('active') : elem.classList.remove('active')
        })
        let videos = ref.current.querySelectorAll('.gallery-item')
        if (tag == 'all') {
            videos.forEach(v => {v.classList.remove('hidden')});
        } else {
            let targets = ref.current.querySelectorAll(`.gallery-item[data-tags*="${tag}"]`)
            videos.forEach(v => {
                [...targets].indexOf(v) >= 0 ? v.classList.remove('hidden') : v.classList.add('hidden')
            });
        }
        renderGallery()
    }
    useEffect(() => {
        let vids = ref.current.querySelectorAll('.gallery-item')
        let targets = ref.current.querySelectorAll(`.gallery-item[data-tags*="Best"]`)
        vids.forEach(v => {
            [...targets].indexOf(v) >= 0 ? v.classList.remove('hidden') : v.classList.add('hidden')
        });
        renderGallery()
        let videos = ref.current.querySelectorAll('video')
        const vplay = (v)=>{v.play()}
        const vpause = (v)=>{v.pause()}
        videos.forEach(v => {
            v.addEventListener('mouseenter',()=>{vplay(v)})
            v.addEventListener('mouseleave',()=>{vpause(v)})
        });
        return(()=>{
            videos.forEach(v => {
                v.removeEventListener('mouseenter',()=>{vplay(v)})
                v.removeEventListener('mouseleave',()=>{vpause(v)})
            });
        })
    },[projects])
    useEffect(() => {
        let tag = 'Best'
        let elem = document.querySelector('.categories a.active')
        if (elem) {tag = elem.getAttribute('data-tag')}
        let vids = ref.current.querySelectorAll('.gallery-item')
        let targets = ref.current.querySelectorAll(`.gallery-item[data-tags*="${tag}"]`)
        vids.forEach(v => {
            [...targets].indexOf(v) >= 0 ? v.classList.remove('hidden') : v.classList.add('hidden')
        });
        renderGallery()
    },[galleryWidth])

    return(
        <div className="page home">
            <section id="main">
                <div className="bg-slider">
                    {data.mainSource == 1 ? <>
                        <div className="bg-slider-row">
                            {data.mainBanner.map(elem => {
                                return(
                                elem.img !== '' ? 
                                  <img key={elem.MIGX_id} src={url + elem.img}></img>  :
                                  <video key={elem.MIGX_id} src={url + elem.vid} muted={true} autoPlay={autoPlay} loop={true}></video>
                                )
                            })}
                        </div>
                        {/* <div className="bg-slider-row">
                            {data.mainBanner.map(elem => {
                                return(
                                elem.img !== '' ? 
                                  <img key={elem.MIGX_id} src={url + elem.img}></img>  :
                                  <video key={elem.MIGX_id} src={url + elem.vid} muted={true} autoPlay={autoPlay} loop={true}></video>
                                )
                            })}
                        </div> */}
                    </> : <>
                        <div className="bg-slider-row fullwidth">
                            {window.innerWidth < 768 ? 
                                data.mainVideoMobile && <iframe src={"https://player.vimeo.com/video/" + data.mainVideoMobile + "?background=1&amp;autoplay=1&amp;muted=1&amp;loop=1&amp;byline=0&amp;title=0"} frameBorder={0} allowFullScreen={true} webkitallowfullscreen="" mozallowfullscreen=""></iframe>
                              : data.mainVideo && <iframe src={"https://player.vimeo.com/video/" + data.mainVideo + "?background=1&amp;autoplay=1&amp;muted=1&amp;loop=1&amp;byline=0&amp;title=0"} frameBorder={0} allowFullScreen={true} webkitallowfullscreen="" mozallowfullscreen=""></iframe>
                            }
                        </div>
                    </>}
                </div>
                <div className="main-text">
                    <div style={{opacity: 0}} className="label">{data.mainLabel}</div>
                    <h1 style={{opacity: 0}}>{data.mainH1}</h1>
                </div>
                <a href="#works" className="main-scroll">
                    <svg width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="0.5" width="27" height="39" rx="13.5" stroke="white"/>
                        <rect x="13.5" y="9" width="2" height="4" rx="1" fill="#D9D9D9"/>
                    </svg>
                    <div className="main-scroll-text">Scroll to view</div>
                </a>
            </section>
            <section id="works">
                <div className='container'>
                    <div className="label">{data.workLabel}</div>
                    <h2>{data.workH}</h2>
                    <div className="categories-wrapper">
                        <div className="categories">
                            {data.workTags.split(',').map((tag,idx) => {
                                return <>
                                    <a href='#'
                                        onClick={(e)=>{filterGallery(e,tag)}}
                                        className={tag == 'Best' ? 'active' : ''}
                                        data-text={tag}
                                        data-tag={tag}
                                    >{tag}</a>
                                    <span className="separator">//</span>
                                </>
                            })}
                            <a href='#' onClick={(e)=>{filterGallery(e,'all')}} data-text="All" data-tag="all">All</a>
                        </div>
                    </div>
                    <div className="gallery" ref={ref}>
                        <div className="gallery-wrapper">
                            {projects.map((p,idx) => {return(
                                <Project key={idx} props={p} />
                            )})}
                        </div>
                    </div>
                </div>
            </section>
            <section id="about">
                <div className='container'>
                    <div className="container-small">
                        <div className="about-wrapper">
                            <img src={url + data.aboutPhoto} alt="Anna Land" className="about-img" />
                            <div className="about-right">
                                <div>
                                    <div className="label">{data.aboutLabel}</div>
                                    <p>{data.aboutText}</p>
                                </div>
                                <a className="btn" href="#contacts">Get in touch</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}